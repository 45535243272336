/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '../../constants/typography';
import { selectIsMobile, selectIsTablet, selectScrollReady } from '../../state/reducers/utils';
import Button from './button';

const ScrollButton = ({
  location = null, 
  arrowNo = null,
  className = '',
  colorClassName = 'text-charcoal',
  positionClassName = 'absolute bottom-8 right-1/2 transform translate-x-1/2 lg:translate-x-0 lg:bottom-16 lg:right-16',
  scrollTo,
  dynamic = false,
  onClickFunc = null,
}) => {
  const isMobile = useSelector(selectIsMobile);
  const isTablet = useSelector(selectIsTablet);
  const scrollReady = useSelector(selectScrollReady);
  const scrollPos = useRef(0);
  useEffect(() => {
    if (window.locoScroll && scrollReady) { 
      window.locoScroll.on('scroll', (e) => {
        scrollPos.current = e.scroll.y;
      });
    }
  }, [scrollReady]);
  const onClick = () => {
    if (onClickFunc) { 
      onClickFunc();
      return;
    }
    const el = document.getElementById(scrollTo);
    if (el) {
      if (dynamic) {
        const newScroll = (isMobile || isTablet)
          ? window.locoScroll.scroll.instance.scroll.y + window.innerHeight
          : scrollPos.current >= window.innerHeight
            ? el.offsetTop
            : window.innerHeight;
        window.locoScroll.scrollTo(newScroll); 
      } else { 
        window.locoScroll.scrollTo(el);
      }
    }
  };
  return (
    <Button
      id={location!=null ? `spotterla_buttonclick_${location}arrow${arrowNo}` : ''}
      className={`
        scroll-button
        group
        flex
        items-center
        h-[24px]
        ${Typography.ScrollButton}
        ${colorClassName}
        ${positionClassName}
        ${className}
      `}
      onClick={onClick}
    >
      <span className="inline-block animate-bounce h-[24px] leading-[45%]">↓</span>
    </Button>
  );
};

export default ScrollButton;
